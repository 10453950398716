export default [
  {
    header: 'Менеджер задач',
    i18n: 'task_manager.header',
    permission: ['admins', 'supervisors', 'accountants', 'employees'],
  },

  {
    title: 'Список счетов',
    i18n: 'client.list_account',
    route: 'task-table',
    icon: 'CheckCircleIcon',
    permission: ['admins', 'supervisors', 'accountants', 'employees'],
  },
  {
    title: 'Список договоров',

    route: 'contracts-list',
    icon: 'PenToolIcon',
    permission: ['admins', 'supervisors', 'accountants', 'employees'],
  },

  {
    title: 'Расчетный счет',

    route: 'checking-account',
    icon: 'ServerIcon',
    permission: ['admins', 'supervisors', 'accountants', 'employees'],
  },
]
