export default [
  {
    header: 'Справочники',
    i18n: 'manual.title',
    permission: ['admins'],
  },
  {
    // tag: store.state.hi,
    title: 'Тип документов',
    i18n: 'manual.doc_type',
    route: 'document-type',
    icon: 'FileTextIcon',
    permission: ['admins'],
  },
  {
    title: 'Плательщики ',
    route: 'payers',
    icon: 'UserIcon',
    permission: ['admins'],
  },
  {
    title: 'Города',
    route: 'citys',
    icon: 'BarChart2Icon',
    permission: ['admins'],
  },
  {
    title: 'Контрагенты',
    route: 'counter-parties',
    icon: 'UsersIcon',
    permission: ['admins'],
  },
]
