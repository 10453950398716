<template>
  <ul>
    <template v-for="item in items">
      <!-- {{item}} -->
      <component
        v-if="checkPerm(item.permission)"
        :is="resolveNavItemComponent(item)"
        :key="item.header || item.title"
        :item="item"
      />
    </template>
  </ul>
</template>

<script>
  import { resolveVerticalNavMenuItemComponent as resolveNavItemComponent } from '@core/layouts/utils'
  import { provide, ref } from '@vue/composition-api'
  import VerticalNavMenuHeader from '../vertical-nav-menu-header'
  import VerticalNavMenuLink from '../vertical-nav-menu-link/VerticalNavMenuLink.vue'
  import VerticalNavMenuGroup from '../vertical-nav-menu-group/VerticalNavMenuGroup.vue'
  import { getUserData } from '@/auth/utils'
  export default {
    components: {
      VerticalNavMenuHeader,
      VerticalNavMenuLink,
      VerticalNavMenuGroup,
    },
    props: {
      items: {
        type: Array,
        required: true,
      },
    },
    setup() {
      provide('openGroups', ref([]))

      return {
        resolveNavItemComponent,
      }
    },
    methods: {
      checkPerm(permission) {

        let useData = getUserData()
        if(useData.is_superuser) return true 

        if (permission) {
          for (let i = 0; i < permission.length; i++) {
          const element = permission[i]
          if (element === 'all' || useData.permission.includes(element)) {
            console.log(element);
            return true
          }
        }
        }
        return false
      },
    },
  }
</script>
