<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div
      class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex"
    >
      <app-breadcrumb class="flex-grow-1" />
      <div class="nav">
        <!-- <locale :locales="langs.locales" /> -->
      </div>
      <dark-toggler class="d-none d-lg-block" />
    </div>

    <!-- <feather-icon
      v-if="chatIcon"
      :class="showChat ? 'text-secondary' : 'text-primary'"
      icon="MessageSquareIcon"
      size="20"
      style="cursor: pointer"
      @click="openChat"
    /> -->

    <b-navbar-nav class="nav align-items-center ml-auto">
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ getUserFullname }}
            </p>
            <span class="user-status">
              <b-badge
                variant="light-primary"
                class="mr-25"
                v-for="role in getUserRole"
                :key="role.id"
              >
                {{ role.name[$i18n.locale] }}
              </b-badge>
            </span>
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            badge
            :src="getUserPhoto"
            class="badge-minimal"
            badge-variant="success"
          />
        </template>

        <!-- <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="showProfile"
        >
          <feather-icon size="16" icon="UserIcon" class="mr-50" />
          <span>{{ $t('general.profile') }}</span>
        </b-dropdown-item> -->

        <!-- <b-dropdown-divider /> -->

        <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
          <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
          <span>{{ $t('general.exit') }}</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink,
  BNavbarNav,
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
  BBadge,
} from 'bootstrap-vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import AppBreadcrumb from '@core/layouts/components/AppBreadcrumb.vue'
import Locale from '@core/layouts/components/app-navbar/components/Locale.vue'
import localeLangs from '@/utils/locales'
import useJwt from '@/auth/jwt/useJwt'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import i18n from '@/libs/i18n'

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    AppBreadcrumb,
    // Navbar Components
    DarkToggler,
    Locale,
    BBadge,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      // user_data: null,
      langs: localeLangs,
    }
  },
  computed: {
    // ...mapState('peal', ['showChat', 'chatIcon']),
    getUserFullname() {
      const data = useJwt.getUserData()
      return data.first_name
    },
    getUserRole() {
      // console.log('jwt ', useJwt.getUserData())
      const data = useJwt.getUserData()
      // console.log('role', data)
      return data.role
    },
    getUserPhoto() {
      const { photo } = useJwt.getUserData()
      return photo
    },
  },
  methods: {
    ...mapMutations('peal', ['SET_OPEN_CHAT']),
    logout() {
      useJwt.logout()
    },
    showProfile() {
      this.$router.push(`/users/edit/${useJwt.getUserData().id}`)
    },
    openChat() {
      this.SET_OPEN_CHAT()
    },
  },
}
</script>
