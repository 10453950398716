<template>
  <!-- <b-nav-item @click="consoleLog"> -->
  <b-nav-item @click="skin = isDark ? 'dark' : 'light'">
    <!--  -->
    <!-- <div>isDark {{ isDark }}</div>
    <div>skin {{ skin }}</div> -->
    <feather-icon size="21" :icon="`${isDark ? 'Sun' : 'Moon'}Icon`" />
  </b-nav-item>
</template>

<script>
import useAppConfig from '@core/app-config/useAppConfig'
import { computed } from '@vue/composition-api'
import { BNavItem } from 'bootstrap-vue'
import { getUserData, setUserData } from '@/auth/utils'

export default {
  components: {
    BNavItem,
  },
  setup(props, { root }) {
    const { skin } = useAppConfig()
    const userData = getUserData()

    // skin.value = userData?.theme || 'light'
    const isDark = computed(() => {
      const theme = skin.value === 'light'
      if (!userData) return theme

      const user = {
        id: userData.id,
        data: { theme: skin.value },
      }

      if (userData.theme != skin.value) {
        root.$store.dispatch('users/UPDATE_USER', user).then(newUserData => {
          setUserData(newUserData.data)
        })
      }

      return theme
    })

    const consoleLog = () => {
      skin.value = isDark ? 'light' : 'dark'
      console.log('Skin', skin.value)
    }
    return { skin, isDark, consoleLog }
  },
}
</script>
