export default [
  {
    header: 'Администрирование',
    i18n: 'administration.title',
    permission: ['admins'],
  },
  {
    title: 'Пользователи',
    i18n: 'administration.users',
    route: 'users',
    icon: 'UserIcon',
    permission: ['admins'],
  },
  {
    // title: "Состояние системы",
    title: 'Состояние системы',
    icon: 'ActivityIcon',
    route: 'system-status',
    permission: ['admins'],
  },
]
